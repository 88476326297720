import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../components/pages/orderStatus/OrderStatus.scss";
import useGlobal from "../../../store";
import close from "../../../assets/close.svg";
import alert from "../../../assets/alert-warning@1x.png";
import { FormInput, FormInputOptional } from "../../common/FormInput";
import DateFormatter from "../../common/Date";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import Button from "react-bootstrap/Button";
import { OrderStatusRemitApi, OrderStatusApi } from "../../../api/OrderStatusApi";
import { Error } from "../../common/index";
import uuid from "react-native-uuid";
import { trackPromise } from 'react-promise-tracker';
import { Spinner } from "../../common/spinner";
import getSitekey from "../../common/GetSiteKey";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from 'react-device-detect';
import {evnetGA} from "../../common/GoogleAnalytics";

function OrderStatus(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [showError, setShowError] = useState({
    showError: false,
    showAPIError: false,
    showMultipleOrder: false,
    showNoOrderFound: false,
    showCaptchaError: false,
  });

  const [showServiceDownError, setShowServiceDownError] = useState(false);

  const SignupSchema = yup.object().shape({
    orderDate: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(new Date("2019-09-01"), t("ORDER_STATUS.NO_STATUS_PROVIDED"))
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),

    orderNumber: yup
      .string()
      .required(t("ORDER_STATUS_ERROR.ORDER_NUMBER"))
      .matches(/^[_FGHNPMVfghnpmv\\s]/, t("ORDER_STATUS_ERROR.REMIT_ERROR"))
      .min('7', t("ORDER_STATUS_ERROR.REMIT_ERROR"))
      .max('7', t("ORDER_STATUS_ERROR.REMIT_ERROR")),


    recordFirstName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("ORDER_STATUS_ERROR.RECORD_FIRST_NAME")
      ),

    recordLastName: yup
      .string()
      .nullable()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("ORDER_STATUS_ERROR.RECORD_LAST_NAME")
      ),

    recordDate: yup
      .date()
      .nullable()
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE_MAX")),
  });

  const RecordTypeList = [
    { value: "Birth Certificate", label: t("ORDER_STATUS.BIRTH_RECORDTYPE") },
    { value: "Death Certificate", label: t("ORDER_STATUS.DEATH_RECORDTYPE") },
    { value: "Birth Resulting in Stillbirth Certificate", label: t("ORDER_STATUS.STILLBIRTHDEATH_RECORDTYPE") },
    { value: "Fetal Death Certificate", label: t("ORDER_STATUS.FETALDEATH_RECORDTYPE") },
    { value: "Marriage Certificate", label: t("ORDER_STATUS.MARRIAGE_RECORDTYPE") },
    { value: "Divorce Certificate", label: t("ORDER_STATUS.DIVORCE_RECORDTYPE") },
  ];


  const siteKey = getSitekey();
  const actionName = 'txEverWithRemit';

  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  useEffect(() => {
    let uniqueIdentifier = globalState.uniqueIdentifier;
    if (!uniqueIdentifier) {
      globalActions.addUniqueIdentifier("OVRAREF_ID-" + uuid.v1());
      uniqueIdentifier = globalState.uniqueIdentifier;
    }
  });

  //DMND0011685 - CaptchaToken Generation and form submission
  const generateCaptchaTokenAndSubmitForm = async (data) =>{
    
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(siteKey, { action: 'submit' })
        .then((token) => {
          data.token=token;
          onSubmit(data);
        })
        .catch((err) => {
          
        });
    });
  }
//DMND0011685 - ------------End-----------------------------

  const onSubmit = async (data) => {
    setShowError({
      showNoOrderFound: false,
      showAPIError: false,
      showError: false,
      showMultipleOrder: false,
      showMultipleOrderRequired: false,
    });
    
    if (data.recordFirstName === "" && data.recordLastName === "" && (data.recordDate === undefined || data.recordDate === null) && data.selectedBirthCertificate === "") {

      var orderStatus = {
        agencyId: "DSHS",
        applicationId: "OVRA",
        uniqueIdentifier: globalState.uniqueIdentifier,
        token: data.token, 
        remitNumber: data.orderNumber,
        orderDate: DateFormatter(data.orderDate, "mm/dd/yyyy"),
      };

      trackPromise(
        OrderStatusApi(orderStatus)
          .then((response) => {
            if (
              response.data.Message !== undefined &&
              (response.data.Message.toLowerCase().indexOf(
                "multiple requests found"
              ) > -1 ||
                response.data.Message.toLowerCase().indexOf(
                  "please provide more details"
                ) > -1)
            ) {
              if (
                data.recordFirstName !== "" &&
                data.recordLastName !== "" &&
                data.recordDate !== "" &&
                data.selectedBirthCertificate !== ""
              ) {
                //evnetGA(_category,_action,_label,_value,_nonInteraction)
            evnetGA("OVRA",
            "OrderStatus",
             "WithRemit_MultipleOrdersFound",
             1,
             true);
                setShowError((prevState) => ({
                  ...prevState,
                  showError: true,
                  showMultipleOrder: true,
                }));
              } else {
                setShowError((prevState) => ({
                  ...prevState,
                  showError: true,
                  showMultipleOrderRequired: true,
                }));
              }
            } else if (
              response.data.Message !== undefined &&
              response.data.Message.toLowerCase().indexOf("order not found") > -1
            ) {
              evnetGA("OVRA",
              "OrderStatus",
               "WithRemit_OrderNotFound",
               1,
               true);
              setShowError((prevState) => ({
                ...prevState,
                showError: true,
                showNoOrderFound: true,
              }));
            } else if (response.data !== "") {
              evnetGA("OVRA","OrderStatus","WithRemit_OrderFound",1,true);
              globalActions.orderStatusRemit(response.data);
              props.nextCallback(2);
            } else {
              setShowError((prevState) => ({
                ...prevState,
                showError: true,
                showAPIError: true,
              }));
            }
          })
      )
        .catch(function (error) {
          console.log("Checkout error", error);
          setShowServiceDownError(true);
        });
    }
    else {

      var orderStatusRemitApi = {
        agencyId: "DSHS",
        applicationId: "OVRA",
        uniqueIdentifier: globalState.uniqueIdentifier,
        token: data.token, 
        remitNumber: data.orderNumber,
        orderDate: DateFormatter(data.orderDate, "mm/dd/yyyy"),
        recordType: data.selectedBirthCertificate ? data.selectedBirthCertificate.value : "",
        dateofEvent: DateFormatter(data.recordDate, "mm/dd/yyyy"),
        registrantFirstName: data.recordFirstName.trim(),
        registrantLastName: data.recordLastName.trim(),
      };
      trackPromise(
        OrderStatusRemitApi(orderStatusRemitApi)
          .then((response) => {
            if (
              response.data.Message !== undefined &&
              (response.data.Message.toLowerCase().indexOf(
                "multiple requests found"
              ) > -1 ||
                response.data.Message.toLowerCase().indexOf(
                  "please provide more details"
                ) > -1)
            ) {
              if (
                data.recordFirstName !== "" &&
                data.recordLastName !== "" &&
                data.recordDate !== "" &&
                data.selectedBirthCertificate !== ""
              ) {
                evnetGA("OVRA",
                "OrderStatus",
                 "MultipleOrdersFound",
                 1,
                 true);
                setShowError((prevState) => ({
                  ...prevState,
                  showError: true,
                  showMultipleOrder: true,
                }));
              } else {
                setShowError((prevState) => ({
                  ...prevState,
                  showError: true,
                  showMultipleOrderRequired: true,
                }));
              }
            } else if (
              response.data.Message !== undefined &&
              response.data.Message.toLowerCase().indexOf("order not found") > -1
            ) {
              evnetGA("OVRA",
              "OrderStatus",
               "OrderNotFound",
               1,
               true);
              setShowError((prevState) => ({
                ...prevState,
                showError: true,
                showNoOrderFound: true,
              }));
            } else if (response.data !== "") {
              evnetGA("OVRA",
              "OrderStatus",
               "OrderFound",
               1,
               true);
              globalActions.orderStatusRemit(response.data);
              props.nextCallback(2);
            } else {
              setShowError((prevState) => ({
                ...prevState,
                showError: true,
                showAPIError: true,
              }));
            }
          })
      )
        .catch(function (error) {
          console.log("Checkout error", error);
          setShowServiceDownError(true);
        });
    }
  };

  return (
    <>
      {!showServiceDownError && (
        <div>
          <div className="order-status-padding">
            <div className="status-border"></div>
            <h1 className="order-status-heading">
              {t("ORDER_STATUS.HEADING")}
            </h1>
            <p className="order-page-content">
              {t("ORDER_STATUS.DESCRIPTION")}
            </p>

            <div id="remiNum">
              <form
                className="order-info-content-section"
                onSubmit={handleSubmit(generateCaptchaTokenAndSubmitForm)} 
              >
                <h2 className="order-info">{t("ORDER_STATUS.INFORMATION")}</h2>

                <FormInput
                  id="orderNumber"
                  name="orderNumber"
                  type="text"
                  label={t("ORDER_STATUS.ORDER_NUMBER")}
                  register={register}
                  className={`birth-info-input answer ${errors.orderNumber ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.orderNumber
                      ? globalState.orderStatusData.orderNumber
                      : ""
                  }
                  error={errors.orderNumber}
                />

                <label htmlFor="orderDate" className="order-info-name">
                  {t("ORDER_STATUS.ORDER_DATE")}
                </label>
                <div className="DoB-date">
                  <Controller
                    control={control}
                    name="orderDate"
                    className={`get-state-birth ${errors.orderDate ? "error-red" : ""
                      }`}
                    render={({ onChange, onBlur, value }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        id="orderDate"
                        className={`input-date get-state-birth ${errors.orderDate ? "error-red" : ""
                          }`}
                        onBlur={onBlur}
                        selected={value}
                        showMonthDropdown
                        showYearDropdown
                        open={isMobile ? false : undefined}
                        customInput={<MaskedInput mask="11/11/1111" />}
                        placeholderText="00/00/0000"
                        defaultValue={
                          globalState.orderStatusData.orderDate
                            ? globalState.orderStatusData.orderDate
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.orderDate && (
                    <p className="error-red-content">
                      {errors.orderDate.message}
                    </p>
                  )}
                </div>

                <label className="name">
                  {t("ORDER_STATUS.TYPE_OF_RECORD")}
                  <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
                  <div className="select-order-align">
                    <Controller
                      name="selectedBirthCertificate"
                      className={`get-state-birth ${errors.selectedBirthCertificate ? "error-red" : ""
                        }`}
                      as={Select}
                      options={RecordTypeList}
                      control={control}
                      rules={{ required: true }}
                      isClearable
                      defaultValue={
                        globalState.orderStatusData.selectedBirthCertificate
                          ? globalState.orderStatusData.selectedBirthCertificate
                            .label
                          : ""
                      }
                    />
                    {errors.selectedBirthCertificate && (
                      <p className="error-red-content">
                        {t("ORDER_STATUS_ERROR.TYPE_OF_RECORD")}
                      </p>
                    )}
                  </div>
                </label>

                <label htmlFor="recordDate" className="order-info-name">
                  {t("ORDER_STATUS.RECORD_EVENT")}
                  <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
                </label>
                <div>
                  <Controller
                    control={control}
                    name="recordDate"
                    className={`get-state-birth ${errors.recordDate ? "error-red" : ""
                      }`}
                    render={({ onChange, onBlur, value }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        id="recordDate"
                        className={`input-date get-state-birth ${errors.recordDate ? "error-red" : ""
                          }`}
                        onBlur={onBlur}
                        selected={value}
                        showMonthDropdown
                        showYearDropdown
                        open={isMobile ? false : undefined}
                        customInput={<MaskedInput mask="11/11/1111" />}
                        placeholderText="00/00/0000"
                      />
                    )}
                  />
                  {errors.recordDate && (
                    <p className="error-red-content">
                      {errors.recordDate.message}
                    </p>
                  )}
                </div>

                <FormInputOptional
                  id="recordFirstName"
                  name="recordFirstName"
                  type="text"
                  label={t("ORDER_STATUS.RECORD_FIRST_NAME")}
                  labelOptional={t("COMMONS.OPTIONAL")}
                  register={register}
                  className={`birth-info-input answer ${errors.recordFirstName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.recordFirstName
                      ? globalState.orderStatusData.recordFirstName
                      : ""
                  }
                  error={errors.recordFirstName}
                />

                <FormInputOptional
                  id="recordLastName"
                  name="recordLastName"
                  type="text"
                  label={t("ORDER_STATUS.RECORD_LAST_NAME")}
                  labelOptional={t("COMMONS.OPTIONAL")}
                  register={register}
                  className={`birth-info-input answer ${errors.recordLastName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.recordLastName
                      ? globalState.orderStatusData.recordLastName
                      : ""
                  }
                  error={errors.recordLastName}
                />
                <Button
                  className="status-link-color order-page-content alternate-way-button"
                  onClick={() => props.nextCallback(1)}
                >
                  {t("ORDER_STATUS.SEARCH_ANOTHER_WAY")}
                </Button>
                {showError.showError && (
                  <Row className="verify-error-message">
                    <Col
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      className="verify-alert-icon"
                    >
                      <img className="verify-alert" src={alert} alt="alert" />
                    </Col>

                    {showError.showAPIError && (
                      <Col
                        className="verify-error-content"
                        sm={8}
                        xs={8}
                        md={8}
                        lg={8}
                      >
                        <p className="verify-error-text">
                          {t("UNABLE_TO_VERIFY.UNABLE_TO_VERIFY_ERROR")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.ORDER_STS1_UNAVAILABLE")}
                        </p>
                      </Col>
                    )}
                    {showError.showMultipleOrder && (
                      <Col
                        className="verify-error-content"
                        sm={8}
                        xs={8}
                        md={8}
                        lg={8}
                      >
                        <p className="verify-error-text">
                          {t("ORDER_STATUS.MULTIPLE_ORDERS_FOUND")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.CONTACT_SUPPORT")}
                        </p>
                      </Col>
                    )}
                    {showError.showMultipleOrderRequired && (
                      <Col
                        className="verify-error-content"
                        sm={8}
                        xs={8}
                        md={8}
                        lg={8}
                      >
                        <p className="verify-error-text">
                          {t("ORDER_STATUS.MULTIPLE_ORDERS_FOUND")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.UNABLE_TO_IDENTIFY_ORDER")}
                        </p>
                      </Col>
                    )}
                    {showError.showNoOrderFound && (
                      <Col
                        className="verify-error-content"
                        sm={8}
                        xs={8}
                        md={8}
                        lg={8}
                      >
                        <p className="verify-error-text">
                          {t("ORDER_STATUS.ORDER_NOT_FOUND")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.ORDER_STS1_UNAVAILABLE")}
                        </p>
                      </Col>
                    )}
                    <Col className="verify-close" sm={2} xs={2} md={2} lg={2}>
                      <img
                        src={close}
                        alt="close button"
                        onClick={() =>
                          setShowError({
                            showNoOrderFound: false,
                            showAPIError: false,
                            showError: false,
                            showMultipleOrder: false,
                            showMultipleOrderRequired: false,
                          })
                        }
                      />
                    </Col>
                  </Row>
                )}
                <div><Spinner /></div>
                <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
                  <Col>
                    <a className="birthverify-back-button button-secondary"
                      href={t("URLS.ORDER_VITAL_RECORDS")}
                    >
                      {t("COMMONS.BACK")}
                    </a>
                  </Col>

                  <Col xs={{ order: isMobileOnly ? 'first' : 'last' }}>
                    <Button
                      variant="outline-primary"
                      className="next-button"
                      type="submit"
                    >
                      {t("ORDER_STATUS.SUBMIT")}
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      )}
      {showServiceDownError && (
        <Error errorReference={globalState.uniqueIdentifier} />
      )}
    </>
  );
};


export default OrderStatus;
