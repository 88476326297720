import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../components/pages/orderStatus/OrderStatus.scss";
import useGlobal from "../../../store";
import close from "../../../assets/close.svg";
import alert from "../../../assets/alert-warning@1x.png";
import { FormInput, FormInputOptional } from "../../common/FormInput";
import DateFormatter from "../../common/Date";
import { Link } from "react-router-dom";
import { Error } from "../../common/index";
import uuid from "react-native-uuid";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import { OrderStatusRemitApi } from "../../../api/OrderStatusApi";
import { trackPromise } from 'react-promise-tracker';
import { Spinner } from "../../common/spinner";
import getSitekey from "../../common/GetSiteKey";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from 'react-device-detect';
import {evnetGA} from "../../common/GoogleAnalytics";

function NoOrderStatus(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [showError, setShowError] = useState({
    showError: false,
    showAPIError: false,
    showMultipleOrder: false,
    showNoOrderFound: false,
    showMultipleOrderRequired: false,
    showCaptchaError: false,
  });

  const siteKey = getSitekey();
  const actionName = 'TxEverNoRemit';

  const [showServiceDownError, setShowServiceDownError] = useState(false);
  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("ORDER_STATUS_ERROR.FIRST_NAME"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("ORDER_STATUS_ERROR.RECORD_FIRST_NAME")
      ),

    lastName: yup
      .string()
      .required(t("ORDER_STATUS_ERROR.LAST_NAME"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("ORDER_STATUS_ERROR.RECORD_LAST_NAME")
      ),

    recordFirstName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("ORDER_STATUS_ERROR.RECORD_FIRST_NAME")
      ),

    recordLastName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("ORDER_STATUS_ERROR.RECORD_LAST_NAME")
      ),

    billingAddress: yup
      .string()
      .required(t("ORDER_STATUS_ERROR.BILLING_ADDRESS")),

    billingCity: yup
      .string()
      .required(t("ORDER_STATUS_ERROR.BILLING_CITY"))
      .matches(
        /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/,
        t("ORDER_STATUS_ERROR.BILLING_CITY")
      ),

    dateOnOrder: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(new Date("2019-09-01"), t("ORDER_STATUS.NO_STATUS_PROVIDED"))
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),


    recordDate: yup
      .date()
      .nullable()
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE_MAX")),

    zipCode: yup
      .string()
      .required(t("ORDER_STATUS_ERROR.ZIP"))
      .min(5)
      .max(10)
      .matches(
        /^[_0-9]*((-|\s)*[_0-9])*$/,
        t("ORDER_STATUS_ERROR.ZIP")
      ),

    selectedBirthCertificate: yup.object().shape({
      label: yup.string().required(t("ORDER_STATUS_ERROR.TYPE_OF_RECORD")),
      value: yup.string().required(t("ORDER_STATUS_ERROR.TYPE_OF_RECORD")),
    }),

  });

  const RecordTypeList = [
    { value: "Birth Certificate", label: t("ORDER_STATUS.BIRTH_RECORDTYPE") },
    { value: "Death Certificate", label: t("ORDER_STATUS.DEATH_RECORDTYPE") },
    { value: "Birth Resulting in Stillbirth Certificate", label: t("ORDER_STATUS.STILLBIRTHDEATH_RECORDTYPE") },
    { value: "Fetal Death Certificate", label: t("ORDER_STATUS.FETALDEATH_RECORDTYPE") },
    { value: "Marriage Certificate", label: t("ORDER_STATUS.MARRIAGE_RECORDTYPE") },
    { value: "Divorce Certificate", label: t("ORDER_STATUS.DIVORCE_RECORDTYPE") },
  ];

  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  useEffect(() => {
    let uniqueIdentifier = globalState.uniqueIdentifier;
    if (!uniqueIdentifier) {
      globalActions.addUniqueIdentifier("OVRAREF_ID-" + uuid.v1());
      uniqueIdentifier = globalState.uniqueIdentifier;
    }
  });

   //DMND0011685 - CaptchaToken Generation and form submission
   const generateCaptchaTokenAndSubmitForm = async (data) =>{
   
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(siteKey, { action: 'submit' })
        .then((token) => {
          data.token=token;
          onSubmit(data);
        })
        .catch((err) => {
          
        });
    });
  }
//DMND0011685 - ------------End-----------------------------

  const onSubmit = async (data) => {
    setShowError({
      showNoOrderFound: false,
      showAPIError: false,
      showError: false,
      showMultipleOrder: false,
      showMultipleOrderRequired: false
    });
    
    var NoRemitdata = {
      agencyId: "DSHS",
      applicationId: "OVRA",
      uniqueIdentifier: globalState.uniqueIdentifier,
      token: data.token,
      orderDate: DateFormatter(data.dateOnOrder, "mm/dd/yyyy"),
      requestorFirstName: data.firstName.trim(),
      requestorLastName: data.lastName.trim(),
      requestorAddress: data.billingAddress.trim(),
      requestorCity: data.billingCity.trim(),
      zip: data.zipCode,
      recordType: data.selectedBirthCertificate ? data.selectedBirthCertificate.value : "",
      dateofEvent: DateFormatter(data.recordDate, "mm/dd/yyyy"),
      registrantFirstName: data.recordFirstName.trim(),
      registrantLastName: data.recordLastName.trim(),
    };

    trackPromise(
      OrderStatusRemitApi(NoRemitdata)
        .then((response) => {

          
          if (response.data.Message !== undefined && (response.data.Message.toLowerCase().indexOf("multiple requests found") > -1 ||
            response.data.Message.toLowerCase().indexOf("please provide more details") > -1)) {
            evnetGA("OVRA",
            "OrderStatus",
             "MultipleOrderFound",
             1,
             true);
            if (data.recordFirstName !== "" && data.recordLastName !== "" && data.recordDate !== "" && data.selectedBirthCertificate !== "") {
              setShowError((prevState) => ({ ...prevState, showError: true, showMultipleOrder: true }));
            }
            else {
              setShowError((prevState) => ({ ...prevState, showError: true, showMultipleOrderRequired: true }));
            }
          }
          else if (response.data.Message !== undefined && response.data.Message.toLowerCase().indexOf("order not found") > -1) {
            evnetGA("OVRA",
            "OrderStatus",
             "OrderNotFound",
             1,
             true);
            setShowError((prevState) => ({ ...prevState, showError: true, showNoOrderFound: true }));
          }
          else if (response.data !== "") {
            evnetGA("OVRA",
            "OrderStatus",
             "OrderFound",
             1,
             true);
            globalActions.orderStatusRemit(response.data);
            props.nextCallback(2);
          } else {
            setShowError((prevState) => ({ ...prevState, showError: true, showAPIError: true }));
          }
        })
    )
      .catch(function (error) {
        console.log("Checkout error", error);
        setShowServiceDownError(true);
      });
  };

  return (
    <>
      {!showServiceDownError && (
        <div>
          <div className="order-status-padding">
            <div className="status-border"></div>
            <h1 className="order-status-heading">
              {t("ORDER_STATUS.HEADING")}
            </h1>
            <p className="order-page-content">
              {t("ORDER_STATUS.DESCRIPTION")}
            </p>

            <div id="remiNum">
              <form
                className="order-info-content-section"
                onSubmit={handleSubmit(generateCaptchaTokenAndSubmitForm)}
              >
                <h2 className="order-info">{t("ORDER_STATUS.INFORMATION")}</h2>
                <label htmlFor="dateOnOrder" className="order-info-name">
                  {t("ORDER_STATUS.ORDER_EVENT")}
                </label>
                <div className="DoB-date">
                  <Controller
                    control={control}
                    name="dateOnOrder"
                    className={`get-state-birth ${errors.dateOnOrder ? "error-red" : ""
                      }`}
                    render={({ onChange, onBlur, value }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        id="dateOnOrder"
                        className={`input-date get-state-birth ${errors.dateOnOrder ? "error-red" : ""}`}
                        onBlur={onBlur}
                        selected={value}
                        showMonthDropdown
                        showYearDropdown
                        open={isMobile ? false : undefined}
                        customInput={<MaskedInput mask="11/11/1111" />}
                        placeholderText="00/00/0000"
                      />
                    )}
                  />
                  {errors.dateOnOrder && (
                    <p className="error-red-content">
                      {errors.dateOnOrder.message}
                    </p>
                  )}
                </div>

                <FormInput
                  id="firstName"
                  name="firstName"
                  type="text"
                  label={t("ORDER_STATUS.FIRST_NAME")}
                  register={register}
                  className={`birth-info-input answer ${errors.firstName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.firstName
                      ? globalState.orderStatusData.firstName
                      : ""
                  }
                  rules={{ required: true }}
                  error={errors.firstName}
                />

                <FormInput
                  id="lastName"
                  name="lastName"
                  type="text"
                  label={t("ORDER_STATUS.LAST_NAME")}
                  register={register}
                  className={`birth-info-input answer ${errors.lastName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.lastName
                      ? globalState.orderStatusData.lastName
                      : ""
                  }
                  rules={{ required: true }}
                  error={errors.lastName}
                />

                <FormInput
                  id="billingAddress"
                  name="billingAddress"
                  type="text"
                  label={t("ORDER_STATUS.BILLING_ADDRESS")}
                  register={register}
                  className={`birth-info-input answer ${errors.billingAddress ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.billingAddress
                      ? globalState.orderStatusData.billingAddress
                      : ""
                  }
                  rules={{ required: true }}
                  error={errors.billingAddress}
                />

                <FormInput
                  id="billingCity"
                  name="billingCity"
                  type="text"
                  label={t("ORDER_STATUS.BILLING_CITY")}
                  register={register}
                  className={`birth-info-input answer ${errors.billingCity ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.billingCity
                      ? globalState.orderStatusData.billingCity
                      : ""
                  }
                  rules={{ required: true }}
                  error={errors.billingCity}
                />

                <FormInput
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  label={t("ORDER_STATUS.ZIP")}
                  register={register}
                  className={`bgZip answer ${errors.zipCode ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.zipCode
                      ? globalState.orderStatusData.zipCode
                      : ""
                  }
                  rules={{ required: true }}
                  error={errors.zipCode}
                />
                <label className="name">
                  {t("ORDER_STATUS.TYPE_OF_RECORD")}
                  <div className="select-order-align">
                    <Controller
                      name="selectedBirthCertificate"
                      className={`get-state-birth ${errors.selectedBirthCertificate ? "error-red" : ""
                        }`}
                      as={Select}
                      options={RecordTypeList}
                      control={control}
                      rules={{ required: true }}
                      isClearable
                      defaultValue={
                        globalState.orderStatusData.selectedBirthCertificate
                          ? globalState.orderStatusData.selectedBirthCertificate
                            .label
                          : ""
                      }
                    />
                    {errors.selectedBirthCertificate && (
                      <p className="error-red-content">
                        {t("ORDER_STATUS_ERROR.TYPE_OF_RECORD")}
                      </p>
                    )}
                  </div>
                </label>
                <label htmlFor="recordDate" className="order-info-name">
                  {t("ORDER_STATUS.RECORD_EVENT")}
                  <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
                </label>
                <div className="DoB-date">
                  <Controller
                    control={control}
                    name="recordDate"
                    className={`get-state-birth ${errors.recordDate ? "error-red" : ""
                      }`}
                    render={({ onChange, onBlur, value }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        id="recordDate"
                        className={`input-date get-state-birth ${errors.recordDate ? "error-red" : ""
                          }`}
                        onBlur={onBlur}
                        selected={value}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="00/00/0000"
                        open={isMobile ? false : undefined}
                        customInput={<MaskedInput mask="11/11/1111" />}
                      />
                    )}
                  />
                  {errors.recordDate && (
                    <p className="error-red-content">
                      {errors.recordDate.message}
                    </p>
                  )}
                </div>

                <FormInputOptional
                  id="recordFirstName"
                  name="recordFirstName"
                  type="text"
                  label={t("ORDER_STATUS.RECORD_FIRST_NAME")}
                  labelOptional={t("COMMONS.OPTIONAL")}
                  register={register}
                  className={`birth-info-input answer ${errors.recordFirstName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.recordFirstName
                      ? globalState.orderStatusData.recordFirstName
                      : ""
                  }
                  error={errors.recordFirstName}
                />

                <FormInputOptional
                  id="recordLastName"
                  name="recordLastName"
                  type="text"
                  label={t("ORDER_STATUS.RECORD_LAST_NAME")}
                  labelOptional={t("COMMONS.OPTIONAL")}
                  register={register}
                  className={`birth-info-input answer ${errors.recordLastName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.orderStatusData.recordLastName
                      ? globalState.orderStatusData.recordLastName
                      : ""
                  }
                  error={errors.recordLastName}
                />
                <Button
                  className="status-link-color order-page-content alternate-way-button"
                  onClick={() => props.nextCallback(0)}
                >
                  {t("ORDER_STATUS.SEARCH_ANOTHER_WAY")}
                </Button>
                {showError.showError && (
                  <Row className="verify-error-message">
                    <Col
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      className="verify-alert-icon"
                    >
                      <img className="verify-alert" src={alert} alt="alert" />
                    </Col>

                    {showError.showAPIError && (
                      <Col
                        className="verify-error-content"
                        sm={8}
                        xs={8}
                        md={8}
                        lg={8}
                      >
                        <p className="verify-error-text">
                          {t("UNABLE_TO_VERIFY.UNABLE_TO_VERIFY_ERROR")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.ORDER_INFO_UNAVAILABLE")}
                        </p>
                      </Col>
                    )}
                    {showError.showMultipleOrder && (
                      <Col
                        className="verify-error-content"
                        sm={8}
                        xs={8}
                        md={8}
                        lg={8}
                      >
                        <p className="verify-error-text">
                          {t("ORDER_STATUS.MULTIPLE_ORDERS_FOUND")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.ORDER_INFO_UNAVAILABLE")}
                        </p>
                      </Col>
                    )}
                    {showError.showMultipleOrderRequired && (
                      <Col
                        className="verify-error-content"
                        sm={8}
                        xs={8}
                        md={8}
                        lg={8}
                      >
                        <p className="verify-error-text">
                          {t("ORDER_STATUS.MULTIPLE_ORDERS_FOUND")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.UNABLE_TO_IDENTIFY_ORDER")}
                        </p>
                      </Col>
                    )}
                    {showError.showNoOrderFound && (
                      <Col
                        className="verify-error-content"
                        sm={8}
                        xs={8}
                        md={8}
                        lg={8}
                      >
                        <p className="verify-error-text">
                          {t("ORDER_STATUS.ORDER_NOT_FOUND")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.ORDER_STS1_UNAVAILABLE")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.ORDER_STS2_UNAVAILABLE")}
                        </p>
                        <p className="verify-error-desc">
                          {t("ORDER_STATUS.ORDER_STS3_UNAVAILABLE")}
                        </p>
                      </Col>
                    )}
                    <Col className="verify-close" sm={2} xs={2} md={2} lg={2}>
                      <img
                        src={close}
                        alt="close button"
                        onClick={() =>
                          setShowError({
                            showNoOrderFound: false,
                            showAPIError: false,
                            showError: false,
                            showMultipleOrder: false,
                            showMultipleOrderRequired: false,
                          })
                        }
                      />
                    </Col>
                  </Row>
                )}
                <Spinner />
                <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
                  <Col>
                    <Link
                      className="birthverify-back-button button-secondary"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        props.prevCallback(0);
                      }}
                    >
                      {t("COMMONS.BACK")}
                    </Link>
                  </Col>
                  <Col xs={{ order: isMobileOnly ? 'first' : 'last' }}>
                    <Button
                      variant="outline-primary"
                      className="next-button"
                      type="submit"
                    >
                      {t("ORDER_STATUS.SUBMIT")}
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      )}
      {showServiceDownError && (
        <Error errorReference={globalState.uniqueIdentifier} />
      )}
    </>
  );
}

export default NoOrderStatus;