import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import "./FetalDeath.scss";
import history from "../../common/history";
import uuid from "react-native-uuid";
import { GetStartedStillbirthDeath, VerifyIdentityInState, VerifyIdentityOutOfState } from "../../common";
import useGlobal from "../../../store";
import { useTranslation } from "react-i18next";
import GetCanonicalUrl from "../../common/GetCanonicalUrl";
import {GetRelationshipData,GetStateData,GetCountyData} from "referencedata";
import StillbirthDeathInfo from "../../common/forms/fetalDeathInfo/StillbirthDeathInfo";

function RequestFetalDeathStillbirth() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [globalState, globalActions] = useGlobal();
  const [arrayIndex, setArrayIndex] = useState("");

  globalState.fetalDeathCertificateInfoData.certificateInfoType = "stillbornDeath";

  const nextStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep + 1);

    value
      ? setUrlForForm("fetalDeath", value)
      : setUrlForForm("fetalDeath", currentStep + 1);
  };
  const prevStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep - 1);
    value
      ? setUrlForForm("fetalDeath", value)
      : setUrlForForm("fetalDeath", currentStep - 1);
  };

  const setUrlForForm = (record, step) => {
    if (record === "fetalDeath") {
      switch (step) {
        case 0:
          history.push(t("URLS.ORDER_STILLBIRTH_DEATH_CERTIFICATE"));
          break;
        case 1:
          history.push(
            t("URLS.ORDER_STILLBIRTH_DEATH_CERTIFICATE") +
              t("URLS.VERIFY_IDENTITY_IN_STATE")
          );
          break;
        case 2:
          history.push(
            t("URLS.ORDER_STILLBIRTH_DEATH_CERTIFICATE") +
              t("URLS.VERIFY_IDENTITY_OUT_STATE")
          );
          break;
        case 3:
          history.push(
            t("URLS.ORDER_STILLBIRTH_DEATH_CERTIFICATE") +
              t("URLS.STILLBIRTH_DEATH_INFORMATION")
          );
          break;
        default:
          history.push(t("URLS.ORDER_STILLBIRTH_DEATH_CERTIFICATE"));
      }
    }
  };

  const firstStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(0);
    value
      ? setUrlForForm("fetalDeath", value)
      : setUrlForForm("fetalDeath", 0);
  };
  useEffect(() => {
    if (!globalState.uniqueIdentifier) {
      globalActions.addUniqueIdentifier("OVRAREF_ID-" + uuid.v1());
    }
    globalActions.clearGlobalState();
    if (
      globalState.saveEditIndex.arrayIndex !== "" &&
      globalState.saveEditIndex.referenceType === "fetalDeath"
    ) {
      setArrayIndex(globalState.saveEditIndex.arrayIndex);
      globalState.getStartedFetalDeathCertificateData.selectedState =
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].selectedState;
      globalState.getStartedFetalDeathCertificateData.selectedRelation =
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].selectedRelation;
      globalState.getStartedFetalDeathCertificateData.dateOfEvent = new Date(
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].dateOfEvent
      );
      globalState.fetalDeathCertificateInfoData
      =
        globalState.totalOrderRecords[globalState.saveEditIndex.arrayIndex];
    }
  }, []);

  return (
    <>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{t("BIRTH_CERTITICATE_INFORMATION.TITLE")}</title>
            <meta
              name="description"
              content={t("BIRTH_CERTITICATE_INFORMATION.META_CONTENT")}
            />

            <link
              rel="canonical"
              href={GetCanonicalUrl() + t("URLS.ORDER_BIRTH_CERTIFICATE_2")}
            />
          </Helmet>
          <Container className="lg-container">
            {currentStep === 0 && (
                <GetStartedStillbirthDeath
                  stateList={GetStateData()}
                  relationList={GetRelationshipData(t("COMMON.LANGUAGE"),"Stillbirth")}
                  nextCallback={nextStep}
                />
              )}
            {currentStep === 1 && (
              <VerifyIdentityInState
                stateList={GetStateData()}
                nextCallback={nextStep}
                prevCallback={prevStep}
                flowIndex="fetalDeath"
              />
            )}
            {currentStep === 2 && (
              <VerifyIdentityOutOfState
                stateList={GetStateData()}
                nextCallback={nextStep}
                prevCallback={firstStep}
                flowIndex="fetalDeath"
              />
            )}
            {currentStep === 3 && (
              <StillbirthDeathInfo
                stateList={GetStateData()}
                countyList={GetCountyData()}
                editIndex={arrayIndex}
              />
            )}
          </Container>
        </div>
    </>
  );
}

export default RequestFetalDeathStillbirth;
