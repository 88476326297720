import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-maskedinput";

import "./GetStartedDeathVerification.scss";
import Progress from "../../Progress";
import Button from "react-bootstrap/Button";
import history from "../../history";
import { useTranslation } from "react-i18next";
import useGlobal from "../../../../store";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { isMobile, isMobileOnly } from "react-device-detect";

function GetStartedStillbirthDeath(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  const GetStartedSchema = yup.object().shape({
    dateOfEvent: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(new Date("1903-01-01"), t("DATE_COMMON_ERRORS.VERIFY_DATE_MIN"))
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE_MAX")),

    validateState: yup.boolean(
      globalState.verifyIdentityData.idVerified === "Y" ? true : false
    ),

    selectedState: yup.object().when("validateState", {
      is: (validateState) => validateState === false,
      then: yup.object().shape({
        label: yup
          .string()
          .required(t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")),

        value: yup
          .string()
          .required(t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")),
      }),
    }),

    selectedRelation: yup.object().shape({
      label: yup
        .string()
        .required(t("GET_STARTED_BIRTHCERTIFICATE.RELATION_ERROR")),
      value: yup
        .string()
        .required(t("GET_STARTED_BIRTHCERTIFICATE.RELATION_ERROR")),
    }),
  });
  const { handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(GetStartedSchema),
  });

  const stateList = props.stateList;
  const relationList = props.relationList;

  const onSubmit = (data) => {
    globalActions.getStartedFetalDeathCertificateData(data);
    if (
      globalState.verifyIdentityData.idVerified !== undefined &&
      globalState.verifyIdentityData.idVerified === "Y"
    ) {
      props.nextCallback(3);
    } else {
      data.selectedState.value === "TX"
        ? props.nextCallback()
        : props.nextCallback(2);
    }
  };

  return (
    <div>
      <Progress currentStep={1} totalSteps={4} />
      <h1 className="birth-heading">
        {t("GET_STARTED_STILLBIRTH.TITLE")}
      </h1>
      <p className="birth-content">{t("GET_STARTED_STILLBIRTH.DESCRIPTION")}</p>
      <label className="sub-get-started">
        {t("GET_STARTED_BIRTHCERTIFICATE.GET_STARTED")}
      </label>
      <p className="birth-content">
        {t("GET_STARTED_STILLBIRTH.SUB_DESCRIPTION")}
      </p>

      <form className="birth-content-section" onSubmit={handleSubmit(onSubmit)}>
        {(globalState.verifyIdentityData.idVerified === undefined ||
          globalState.verifyIdentityData.idVerified === "" ||
          globalState.verifyIdentityData.idVerified === "N") && (
          <>
            <div className="select-align">
              <label className="birth-content">
                {t("COMMONS.CHOOSE_STATE")}
                <Controller
                  id="selectedState"
                  name="selectedState"
                  className={`field-label-padding ${
                    errors.selectedState ? "error-red" : ""
                  }`}
                  as={Select}
                  options={stateList}
                  control={control}
                  isClearable
                  defaultValue={
                    globalState.getStartedFetalDeathCertificateData.selectedState
                      .label
                      ? globalState.getStartedFetalDeathCertificateData.selectedState
                      : ""
                  }
                  rules={{ required: true }}
                />
                {errors.selectedState && (
                  <p className="error-red-content">
                    {t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")}
                  </p>
                )}
              </label>
            </div>
          </>
        )}

        <div className="select-align">
          <label className="birth-content">
            {t("GET_STARTED_STILLBIRTH.RELATIONSHIP_TO_PERSON")}
            <Controller
              id="selectedRelation"
              name="selectedRelation"
              className={`field-label-padding ${
                errors.selectedRelation ? "error-red" : ""
              }`}
              as={Select}
              options={relationList}
              control={control}
              rules={{ required: true }}
              isClearable
              defaultValue={
                globalState.getStartedFetalDeathCertificateData.selectedRelation
                  .label
                  ? globalState.getStartedFetalDeathCertificateData.selectedRelation
                  : ""
              }
            />
            {errors.selectedRelation && (
              <p className="error-red-content">
                {t("GET_STARTED_BIRTHCERTIFICATE.RELATION_ERROR")}
              </p>
            )}
          </label>
        </div>

        <label htmlFor="dateOfEvent" className="birth-content">
          {t("GET_STARTED_STILLBIRTH.DATE_OF_EVENT")}
        </label>
        <div className="birth-date">
          <Controller
            control={control}
            name="dateOfEvent"
            rules={{ required: true }}
            defaultValue={
              globalState.getStartedFetalDeathCertificateData.dateOfEvent
                ? 
              globalState.getStartedFetalDeathCertificateData.dateOfEvent : ""
            }
            className="input-date"
            render={({ onChange, onBlur, value, name }) => (
              <ReactDatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                id="dateOfEvent"
                className={`input-date get-state-birth ${
                  errors.dateOfEvent ? "error-red" : ""
                }`}
                showMonthDropdown
                showYearDropdown
                open={isMobile ? false : undefined}
                customInput={<MaskedInput mask="11/11/1111" />}
                placeholderText="00/00/0000"
              />
            )}
          />
          {errors.dateOfEvent && (
            <p className="error-red-content">
              {errors.dateOfEvent.message.indexOf("dateOfEvent must") !== -1
                ? t("GET_STARTED_BIRTHCERTIFICATE.DATE_OF_BIRTH_ERROR_1")
                : errors.dateOfEvent.message}
            </p>
          )}
        </div>

        <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
          <Col pull-right>
            <Button
              variant="outline-primary"
              className="back-button"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push(t("URLS.SELECT_VITAL_RECORD"));
              }}
            >
              {t("COMMONS.BACK")}
            </Button>
          </Col>
          <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
            <Button
              variant="primary"
              className="next-button pull-right next-button-birth-mobile-align"
              type="submit"
            >
              {t("COMMONS.NEXT")}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default GetStartedStillbirthDeath;