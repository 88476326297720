import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

export const SelectField = ({
  register,
  label,
  name,
  id,
  options,
  control,
  defaultValue,
  number,
  onChange,
  className,
  error,
  rules,
  isClearable,
  ...inputProps
}) => {
  return (
    <>
      <label htmlFor={id} className="birth-content">
        {label}
      </label>
      <div className="format-info">
        <Controller
          name={name}
          as={Select}
          options={options}
          className={className}
          control={control}
          rules={rules}
          isClearable
          defaultValue={defaultValue}
          {...inputProps}
          onChange={onChange}
          render={({ onChange, onBlur, value }) => (
            <Select onChange={onChange} onBlur={onBlur} options={options} />
          )}
        />
      </div>
      {error && <p className="error-red-content">{error.message}</p>}
    </>
  );
};

export const SelectFieldOptional = ({
  register,
  label,
  labelOptional,
  name,
  id,
  options,
  control,
  defaultValue,
  number,
  className,
  error,
  rules,
  isClearable,
  ...inputProps
}) => {
  return (
    <>
      <label htmlFor={id} className="birth-content">
        {label}
      </label>
      <label htmlFor={id} className="optional-text">
        {labelOptional}
      </label>
      <div className="format-info">
        <Controller
          name={name}
          as={Select}
          options={options}
          className={className}
          control={control}
          rules={rules}
          isClearable
          defaultValue={defaultValue}
          {...inputProps}
        />
      </div>
      {error && <p className="error-red-content">{error.message}</p>}
    </>
  );
};
export const NumberField = ({
  register,
  label,
  name,
  id,
  options,
  control,
  defaultValue,
  number,
  error,
  width,
  ...inputProps
}) => {
  return (
    <>
      <label htmlFor={id} className="birth-content field-label-padding">
        {label}
      </label>
      <div className={`info-input-align ${width}`}>
        <input
          ref={register}
          id={id}
          className="no-of-copies answer"
          name={name}
          type={number}
          defaultValue={defaultValue}
          {...inputProps}
        />
      </div>
      {error && <p className={`error-red-content ${width}`}>{error.message}</p>}
    </>
  );
};
