import React, { useState, useEffect } from "react";
import useGlobal from "../../../../store";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import GetCanonicalUrl from "../../../common/GetCanonicalUrl";

import { useTranslation } from "react-i18next";

import "./RequestVitalRecord.scss";

function RequestVitalRecord() {
  const { t } = useTranslation();
  const [showCancel, setShowCancel] = useState(false);
  const [globalState] = useGlobal();

  useEffect(() => {
    if (
      globalState.totalOrderRecords !== undefined &&
      globalState.totalOrderRecords.length > 0
    ) {
      setShowCancel(true);
    }
  });

  globalState.saveEditIndex.arrayIndex = "";
  globalState.saveEditIndex.referenceType = "";

  const cards = [
    {
      mainTitle: "SELECT_VITAL_RECORD.BIRTH_RECORDS",
      actions: [
        {
          title: "SELECT_VITAL_RECORD.BIRTH_CERTIFICATE",
          description: "SELECT_VITAL_RECORD.BIRTH_RECORD_DESC",
          url: t("URLS.ORDER_BIRTH_CERTIFICATE"),
        },
        {
          title: "SELECT_VITAL_RECORD.BIRTH_VERIFICATION",
          description: "SELECT_VITAL_RECORD.BIRTH_VERIFICATION_DESC",
          url: t("URLS.ORDER_BIRTH_VERIFICATION_LETTER"),
        },
      ],
    },
    {
      mainTitle: "SELECT_VITAL_RECORD.DEATH_RECORDS",
      actions: [
        {
          title: "SELECT_VITAL_RECORD.DEATH_CERTIFICATE",
          description: "SELECT_VITAL_RECORD.DEATH_CERTIFICATE_DESC",
          url: t("URLS.ORDER_DEATH_CERTIFICATE"),
        },
        {
          title: "SELECT_VITAL_RECORD.DEATH_VERIFICATION",
          description: "SELECT_VITAL_RECORD.DEATH_VERIFICATION_DESC",
          url: t("URLS.ORDER_DEATH_VERIFICATION_LETTER"),
        },
      ],
    },
    {
      mainTitle: "SELECT_VITAL_RECORD.FETAL_DEATH_RECORDS",
      actions: [
        {
          title: "ORDER_REVIEW.STILLBIRTH_CERTIFICATE",
          description: "SELECT_VITAL_RECORD.STILLBIRTH_CERTIFICATE_DESC",
          url: t("URLS.ORDER_STILLBIRTH_DEATH_CERTIFICATE"),
        },
        {
          title: "ORDER_REVIEW.FETALDEATH_CERTIFICATE",
          description: "SELECT_VITAL_RECORD.FETALDEATH_CERTIFICATE_DESC",
          url: t("URLS.ORDER_FETAL_DEATH_CERTIFICATE"),
        },
      ],
    },
    {
      mainTitle: "SELECT_VITAL_RECORD.MARRIAGE_RECORDS",
      actions: [
        {
          title: "SELECT_VITAL_RECORD.MARRIAGE_VERIFICATION",
          description: "SELECT_VITAL_RECORD.MARRIAGE_VERIFICATION_DESC",
          url: t("URLS.ORDER_MARRIAGE_VERIFICATION_LETTER"),
        },
        {
          title: "SELECT_VITAL_RECORD.DIVORCE_VERIFICATION",
          description: "SELECT_VITAL_RECORD.DIVORCE_VERIFICATION_DESC",
          url: t("URLS.ORDER_DIVORCE_VERIFICATION_LETTER"),
        },
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("SELECT_VITAL_RECORD.TITLE")}</title>
        <meta
          name="description"
          content={t("SELECT_VITAL_RECORD.META_CONTENT")}
        />
         <link rel="canonical" href={GetCanonicalUrl() + t('URLS.SELECT_VITAL_RECORD_2')}/>
           
      </Helmet>
      <Container className="vital-record-container">
        <div className="vital-top-line"></div>

        <h1 className="vital-record-heading">
          {t("SELECT_VITAL_RECORD.HEADING")}
        </h1>
        <label className="vital-record-sub-heading">
          {t("SELECT_VITAL_RECORD.SUB_HEADING")}
        </label>
        {cards.map((card) => {
          return (
            <>
              <h2 className="section-headings">{t(card.mainTitle)}</h2>
              <div className="option-container">
                {card.actions.map((cardaction) => {
                  return (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={cardaction.url}
                    >
                      {" "}
                      <Row
                        className="req-vital-birth"
                        sm={1}
                        xs={1}
                        md={2}
                        lg={2}
                      >
                        <Col lg={8} md={8}>
                          <h3 className="vital-section-titles">
                            {t(cardaction.title)}
                          </h3>
                          <p className="vital-section-desc">
                            {t(cardaction.description)}
                          </p>
                        </Col>
                        <Col className="vital-button-align" lg={4} md={4}>
                          {" "}
                          <span
                            className="button-primary select-vital-button"
                            to={cardaction.url}
                          >
                            {t("COMMONS.SELECT")}
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  );
                })}
              </div>
            </>
          );
        })}
        {!showCancel && (
          <>
            <div className="vital-back-button-align">
              <a className="vital-back-button button-secondary"
                href={t("URLS.ORDER_VITAL_RECORDS")}
              >
                {t("COMMONS.BACK")}
              </a>
            </div>
          </>
        )}
        {showCancel && (
          <>
            <div className="vital-back-button-align">
              <Link
                className="vital-back-button button-secondary"
                to={t("URLS.ORDER_REVIEW")}
              >
                {t("COMMONS.CANCEL")}
              </Link>
            </div>
          </>
        )}
        {/* <div className="height-fix-mobile"></div> */}
      </Container>
    </>
  );
}

export default RequestVitalRecord;
