import React from "react";
import useGlobalHook from "use-global-hook";

import * as actions from "../actions";

const initialState = {
  gaClientId:"",
  publicHost: "",
  getStartedBirthCertificateData: {
    selectedRelation: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    dateOfBirth: "",
  },
  getStartedDeathCertificateData: {
    selectedRelation: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    dateOfDeath: "",
  },
  verifyIdentityData: {
    selectedState: "",
    previousSelectedState: { label: "", value: "" },
  },
  addOrderNumber: "",
  documentTypeFlagOne: false,
  documentTypeFlagTwo: false,

  birthCertificateInfoData: {
    certificateInfoType: "birthCertificate",
    uid: "",
    cityOfBirth: "",
    selectedGender: { label: "", value: "" },
    lastName: "test",
    middleName: "",
    firstName: "",
    firstNameParent1: "",
    middleNameParent1: "",
    lastNameParent1: "",
    noOfCopies: "",
    dateOfBirth: "",
    countyOfBirth: {
      label: "",
      value: "",
    },
    selectedBirthCertificate: {
      value: "",
      label: "",
    },
    selectedPurposeList: {
      value: "",
      label: "",
    },
    orderDetails: [
      {
        recordType: "",
        numberOfCopies: 0,
      },
    ],
    totalNoOfCopies: 0,
    selectedRelation: { label: "", value: "" },
    selectedState: { label: "", value: "" },
  },

  uniqueIdentifier: "",
  checkOutOrder: {
    applicationId: "OVRA",
    agencyId: "DSHS",
    uniqueIdentifier: "OVRAREF_ID",
    inTransactionId: "0",
    totalAmount: "999",
    orderNumber: "2068532",
    transactionStatus: "NOT PAID",
    checkoutConfiguration: {
      host: "72.182.76.89",
      viewOptions: {
        cvvRequired: true,
        achRequired: false,
        addressRequired: true,
        allowedCardTypes: [],
      },
    },
  },

  deathCertificateInfoData: {
    certificateInfoType: "deathCertificate",
    uid: "",
    selectedGender: { label: "", value: "" },
    countyOfDeath: {
      label: "",
      value: "",
    },
    stateOfBirth: {
      label: "",
      value: "",
    },
    selectedRelation: { label: "", value: "" },
    funeralHomeID: "",
    dateOfDeath: "",
    purposeList: { label: "", value: "" },
  },
  getStartedFetalDeathCertificateData: {
    selectedRelation: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    dateOfEvent: "",
  },
  fetalDeathCertificateInfoData: {
    certificateInfoType: "",
    uid: "",
    cityOfBirth: "",
    selectedGender: { label: "", value: "" },
    lastName: "",
    middleName: "",
    firstName: "",
    firstNameParent1: "",
    middleNameParent1: "",
    lastNameParent1: "",
    firstNameParent2: "",
    middleNameParent2: "",
    lastNameParent2: "",
    noOfCopies: "",
    dateOfBirth: "",
    cityOfEvent: "",
    dateOfEvent: "",
    countyOfEvent: {
      label: "",
      value: "",
    },
    selectedBirthCertificate: {
      value: "",
      label: "",
    },
    selectedPurposeList: {
      value: "",
      label: "",
    },
    orderDetails: [
      {
        recordType: "",
        numberOfCopies: 0,
      },
    ],
    totalNoOfCopies: 0,
    selectedRelation: { label: "", value: "" },
    selectedState: { label: "", value: "" },
  },

  jwtToken: "",

  paymentItemizedTotal: {},

  shippingInformationData: {
    selectedState: {
      label: "",
      value: "",
    },
    selectedShippingType: {
      label: "",
      value: "",
    },
  },
  amountDistribution: {},
  paymentFormData: {},
  orderStatusData: {},
  applicationId: "ovra",
  host: "our public ip address",
  address: {
    address1: "500 W",
    address2: "2nd St",
    city: "Austin",
    state: "TX",
    postalCode: "78701",
  },
  getStarteDeathVerificationInfo: {
    selectedState: {
      label: "",
      value: "",
    },
    dateOfDeath: "",
  },
  deathVerificationInfo: {
    certificateInfoType: "deathVerification",
    uid: "",
    selectedGender: { label: "", value: "" },
    countyOfDeath: { label: "", value: "" },
    stateOfBirth: { label: "", value: "" },
    dateOfDeath: "",
  },

  getStartedDivorceVerificationInfoData: {
    selectedState: {
      label: "",
      value: "",
    },
    dateOfDivorce: "",
  },

  divorceVerificationInfoData: {
    certificateInfoType: "divorceVerification",
    uid: "",
    countyOfDivorce: {
      label: "",
      value: "",
    },
    dateOfDivorce: "",
  },

  birthVerificationInfo: {
    certificateInfoType: "birthVerification",
    uid: "",
    selectedGender: { label: "", value: "" },
    countyOfBirth: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    dateOfBirth: "",
  },

  getStartedBirthVerificationInfo: {
    dateOfBirth: "",
    selectedState: { label: "", value: "" },
  },

  marriageVerificationInfo: {
    certificateInfoType: "marriageVerification",
    uid: "",
    countyOfMarriage: { label: "", value: "" },
  },
  getStartedMarriageVerificationInfo: {
    selectedState: {
      label: "",
      value: "",
    },
    dateOfMarriage: "",
  },

  orderStatusRemitData: {},
  orderReview: {
    donationChecked: false,
  },
  totalOrderRecords: [],
  referenceDataList: {
    stateList: [],
    countyList: [],
  },
  saveEditIndex: {
    arrayIndex: "",
    referenceType: "",
  },
};
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
